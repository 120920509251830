import React from "react";
import { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { useEffect } from "react";
import Aos from "aos";

const ServiceCard = (props) => {
  const [toggle, setToggle] = useState();
  const changeToggle = (index) => {
    setToggle(index);
    // console.log(index)
  };
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div
        onMouseOver={() => changeToggle(1)}
        onMouseOut={() => changeToggle(0)}
        data-aos="fade-up"
        className={`w-full max-w-sm flex justify-start items-start flex-col space-y-2 sm:py-4 sm:px-6 p-4  rounded-lg bg-transparent border border-2 border-sky-500 relative serviceCardAnimation  overflow-hidden`}
      >
        <h5 className="w-full h-full bg-sky-500  "></h5>
        <div className="z-50 bg-transparent">
          <div>
            <span
              className={`sm:text-5xl text-4xl ${
                toggle === 1 ? "text-white" : "text-sky-500"
              } font-normal duration-300`}
            >
              {props.data.icon}
            </span>
          </div>
          <div className="space-y-2">
            <h1
              className={`w-full text-start   sm:text-2xl text-xl font-semibold ${
                toggle === 1 ? "text-white" : "text-black"
              } duration-300 capitalize`}
            >
              {props.data.name}
            </h1>
            <p
              className={`w-full text-start sm:text-base  text-sm font-light font-sans  leading-8 text-black ${
                toggle === 1 ? "text-white" : "text-black"
              } duration-300`}
            >
              {props.data.details}
            </p>
          </div>
          <button
            className={`${
              toggle === 1 ? "text-white" : "text-sky-500"
            } font-semibold sm:text-base text-sm my-2 duration-300`}
          >
            <a className="flex justify-start items-center w-full">
              Read More{" "}
              <span className="inline-flex mx-2 font-bold">
                {" "}
                <BsArrowRight />
              </span>
            </a>
          </button>
        </div>
      </div>
    </>
  );
};

export default ServiceCard;
