import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import PortfolioPage from "./components/Portfolio/PortfolioPage";

import Footer from "./components/Footer";

class ErrorBoundary extends React.Component {
	componentDidCatch(error, errorInfo) {
		console.error("Error caught by error boundary:", error, errorInfo);
	}

	render() {
		return this.props.children;
	}
}

// Wrap your App component with ErrorBoundary
const App = () => {
	return (
		<div className="maindiv">
			<BrowserRouter>
				<Routes>
					<Route
						path="/"
						element={
							<ErrorBoundary>
								<Home />
							</ErrorBoundary>
						}
					/>
					<Route path="/portfolio-page/:id" element={<PortfolioPage />} />
				</Routes>
			</BrowserRouter>
			<Footer />
		</div>
	);
};

// const App = () => {
// 	return (
// 		<BrowserRouter>
// 			<Routes>
// 				<Route path="/" component={Home} />
// 			</Routes>
// 		</BrowserRouter>
// 	);
// };

export default App;
