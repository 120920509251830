import { VscLightbulbAutofix } from "react-icons/vsc";
import { BsFillClipboardDataFill } from "react-icons/bs";
import { FaRegUser } from "react-icons/fa";
import { TbTargetArrow } from "react-icons/tb";

export const WorkingData = [
  {
    id: 1,
    work: "  finished projects",
    count: "100+",
    icon: <VscLightbulbAutofix />,
  },
  {
    id: 2,
    work: "  Created jobs",
    count: "23+",
    icon: <BsFillClipboardDataFill />,
  },
  {
    id: 3,
    work: "  happy customers",
    count: "75+",
    icon: <FaRegUser />,
  },
  {
    id: 4,
    work: "  years Of exprience",
    count: "4+",
    icon: <TbTargetArrow />,
  },
];
