import React, { Component } from "react";
import { PortfolioData } from "../components/Portfolio/PortfolioData";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { BsArrowRight } from "react-icons/bs";
import img1 from "../img/1.jpg";
import img2 from "../img/2.jpg";
import img3 from "../img/3.jpg";
import img4 from "../img/4.jpg";
import img5 from "../img/5.jpg";
import img6 from "../img/6.jpg";
export default class PortfolioTestimonial extends Component {
	render() {
		return (
			<>
				<div className="mb-10 bg-black-50 w-100vw">
					<Carousel
						showArrows={true}
						infiniteLoop={true}
						showThumbs={false}
						showStatus={false}
						autoPlay={true}
						interval={6100}
						className="bg-transparent"
					>
						<div className="w-full  flex justify-start items-start overflow-hidden  relative main-div cursor-pointer">
							<img
								className="w-full h-[300px] md:h-[360px]  transition ease-in-out delay-100  hover:scale-125 duration-300 z-0  rounded-0"
								src={img1}
							/>
							<button className="text-white font-semibold bg-sky-500  md:p-5  p-2  sm:px-2  px-4 text-center sm:text-xl text-base capitalize absolute  z-50 h-12 md:h-16">
								<BsArrowRight />
							</button>
							<h1 className="text-white font-semibold bg-black/70 w-full md:p-4  p-2  text-center sm:zxt-xl text-base capitalize absolute z-0 h-12 md:h-16 ">
								webdevloper
							</h1>
						</div>
						<div className="w-full  flex justify-start items-start overflow-hidden  relative main-div cursor-pointer">
							<img
								className="w-full h-[300px] md:h-[360px]  transition ease-in-out delay-100  hover:scale-125 duration-300 z-0 "
								src={img2}
							/>
							<button className="text-white font-semibold bg-sky-500  md:p-5  p-2  sm:px-2  px-4 text-center sm:text-xl text-base capitalize absolute  z-50 h-12 md:h-16">
								<BsArrowRight />
							</button>
							<h1 className="text-white font-semibold bg-black/70 w-full md:p-4  p-2  text-center sm:zxt-xl text-base capitalize absolute z-0 h-12 md:h-16 ">
								webdevloper
							</h1>
						</div>
						<div className="w-full  flex justify-start items-start overflow-hidden  relative main-div cursor-pointer">
							<img
								className="w-full h-[300px] md:h-[360px]  transition ease-in-out delay-100  hover:scale-125 duration-300 z-0 "
								src={img3}
							/>
							<button className="text-white font-semibold bg-sky-500  md:p-5  p-2  sm:px-2  px-4 text-center sm:text-xl text-base capitalize absolute  z-50 h-12 md:h-16">
								<BsArrowRight />
							</button>
							<h1 className="text-white font-semibold bg-black/70 w-full md:p-4  p-2  text-center sm:zxt-xl text-base capitalize absolute z-0 h-12 md:h-16 ">
								webdevloper
							</h1>
						</div>
						<div className="w-full  flex justify-start items-start overflow-hidden  relative main-div cursor-pointer">
							<img
								className="w-full h-[300px] md:h-[360px]  transition ease-in-out delay-100  hover:scale-125 duration-300 z-0 "
								src={img4}
							/>
							<button className="text-white font-semibold bg-sky-500  md:p-5  p-2  sm:px-2  px-4 text-center sm:text-xl text-base capitalize absolute  z-50 h-12 md:h-16">
								<BsArrowRight />
							</button>
							<h1 className="text-white font-semibold bg-black/70 w-full md:p-4  p-2  text-center sm:zxt-xl text-base capitalize absolute z-0 h-12 md:h-16 ">
								webdevloper
							</h1>
						</div>
						<div className="w-full  flex justify-start items-start overflow-hidden  relative main-div cursor-pointer">
							<img
								className="w-full h-[300px] md:h-[360px]  transition ease-in-out delay-100  hover:scale-125 duration-300 z-0 "
								src={img5}
							/>
							<button className="text-white font-semibold bg-sky-500  md:p-5  p-2  sm:px-2  px-4 text-center sm:text-xl text-base capitalize absolute  z-50 h-12 md:h-16">
								<BsArrowRight />
							</button>
							<h1 className="text-white font-semibold bg-black/70 w-full md:p-4  p-2  text-center sm:zxt-xl text-base capitalize absolute z-0 h-12 md:h-16 ">
								webdevloper
							</h1>
						</div>
						<div className="w-full  flex justify-start items-start overflow-hidden  relative main-div cursor-pointer">
							<img
								className="w-full h-[300px] md:h-[360px]  transition ease-in-out delay-100  hover:scale-125 duration-300 z-0 "
								src={img6}
							/>
							<button className="text-white font-semibold bg-sky-500  md:p-5  p-2  sm:px-2  px-4 text-center sm:text-xl text-base capitalize absolute  z-50 h-12 md:h-16">
								<BsArrowRight />
							</button>
							<h1 className="text-white font-semibold bg-black/70 w-full md:p-4  p-2  text-center sm:zxt-xl text-base capitalize absolute z-0 h-12 md:h-16 ">
								webdevloper
							</h1>
						</div>
					</Carousel>
				</div>
			</>
		);
	}
}
