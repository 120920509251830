import { CiMedal } from "react-icons/ci";
import { FaMapLocationDot } from "react-icons/fa6";
import { PiHandCoinsLight } from "react-icons/pi";

import { IoGameControllerOutline } from "react-icons/io5";


export const AboutData = [
    {
      id: 1,
      name : "first on field ",
      details : "Pioneering solutions and setting standards in the industry with innovative approaches and top-notch quality",
      icon:<CiMedal/>

    },
    {
        id: 2,
        name : "easy to reach ",
        details : "Ensuring accessibility and simplicity, our solutions provide easy and efficient experiences for all users",
        icon:<IoGameControllerOutline/>
  
      },
      {
        id: 3,
        name : "worldwide services",
        details : "Delivering services that transcend borders, connecting businesses with a worldwide network for unparalleled impact",
        icon:<FaMapLocationDot/>
  
      },
      {
        id: 4,
        name : " 24/7 support  ",
        details : "Providing unwavering support 24/7, ensuring your business is always backed by our dedicated and responsive team.",
        icon:<PiHandCoinsLight/>
  
      },

          
  ];

  
  