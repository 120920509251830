import React, { useRef } from "react";

const Constrain = 200;

const WorkingCard = (props) => {
  const mouseOverContainerRef = useRef(null);
  const ex1LayerRef = useRef(null);

  function transforms(x, y, el) {
    const box = el.getBoundingClientRect();
    const calcX = -(y - box.y - box.height / 2) / Constrain;
    const calcY = (x - box.x - box.width / 2) / Constrain;

    return `perspective(100px) rotateX(${calcX}deg) rotateY(${calcY}deg)`;
  }

  function transformElement(el, xyEl) {
    el.style.transform = transforms(...xyEl);
  }

  function handleMouseMove(e) {
    const xy = [e.clientX, e.clientY];
    const position = xy.concat([ex1LayerRef.current]);

    window.requestAnimationFrame(() => {
      transformElement(ex1LayerRef.current, position);
    });
  }


  return (
    <>
      <div
        id="ex1"
        ref={mouseOverContainerRef}
        onMouseMove={handleMouseMove}
        className="w-5/6 max-w-sm h-full flex-justify-center items-center"
      >
        <div
          id="ex1-layer"
          ref={ex1LayerRef}
          className="w-full bg-black/20  border border-1 border-t-4 border-t-sky-500 border-sky-800  flex justify-center items-center flex-col p-4 py-8 max-w-sm"
        >
          <span className="text-sky-700  text-3xl sm:text-5xl">
            {props.data.icon}
          </span>
          <h1 className="text-white font-bold text-3xl sm:text-5xl my-3">
            {props.data.count}
          </h1>
          <p className="text-white font-normal text-sm sm:text-xl capitalize">
            {props.data.work}
          </p>
        </div>
      </div>
    </>
  );
};

export default WorkingCard;
