import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const PortfolioCard = (props) => {
	const [value, setValue] = useState(null);

	const clickCard = (index) => {
		setValue(index);
		console.log("Clicked card with value:", index);
	};
	return (
		<>
			<Link to={`/portfolio-page/${props.data.id}`}>
				<div className="w-full flex justify-start items-start overflow-hidden relative main-div cursor-pointer">
					<img
						className="w-full h-[300px] md:h-[360px] transition ease-in-out delay-100 hover:scale-125 duration-300 z-0"
						src={props.data.image}
						alt={props.data.category}
					/>
					<button className="text-white font-semibold bg-sky-500 md:p-5 p-2 sm:px-2 px-4 text-center sm:text-xl text-base capitalize absolute z-50 h-12 md:h-16">
						<BsArrowRight />
					</button>
					<h1 className="text-white font-semibold bg-black/70 w-full md:p-4 p-2 text-center sm:zxt-xl text-base capitalize absolute z-0 h-12 md:h-16 ">
						{props.data.category}
					</h1>
				</div>
			</Link>
		</>
	);
};

export default PortfolioCard;

// import React, { useState } from "react";
// import { BsArrowRight } from "react-icons/bs";
// import { NavLink, useNavigate } from "react-router-dom";

// const PortfolioCard = (props) => {
// 	const [value, setValue] = useState();
// 	function clickCard(index) {
// 		setValue(index);
// 	}
// 	const id = 5;
// 	return (
// 		<>
// 			<NavLink
// 				to={{ pathname: "/portfolio-page", state: { yourValue: value } }}
// 			>
// 				<button onClick={clickCard(props.data.id)}>
// 					<div className="w-full  flex justify-start items-start overflow-hidden  relative main-div cursor-pointer">
// 						<img
// 							className="w-full h-[300px] md:h-[360px]  transition ease-in-out delay-100  hover:scale-125 duration-300 z-0 "
// 							src={props.data.image}
// 						/>
// 						<button className="text-white font-semibold bg-sky-500  md:p-5  p-2  sm:px-2  px-4 text-center sm:text-xl text-base capitalize absolute  z-50 h-12 md:h-16">
// 							<BsArrowRight />
// 						</button>
// 						<h1 className="text-white font-semibold bg-black/70 w-full md:p-4  p-2  text-center sm:zxt-xl text-base capitalize absolute z-0 h-12 md:h-16 ">
// 							{props.data.category}
// 						</h1>
// 					</div>
// 				</button>
// 			</NavLink>
// 		</>
// 	);
// };

// export default PortfolioCard;
