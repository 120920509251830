import React, { useRef } from "react";
import icon from "../../img/3.png";
import { BsDot } from "react-icons/bs";
import { AboutData } from "./AboutData";
import AboutCard from "./AboutCard";
import { useEffect } from "react";
import Aos from "aos";

const Constrain = 200;
const AboutUs = () => {
	const aboutRef = useRef(null);
	const mouseOverContainerRef = useRef(null);
	const ex1LayerRef = useRef(null);

	function transforms(x, y, el) {
		const box = el.getBoundingClientRect();
		const calcX = -(y - box.y - box.height / 2) / Constrain;
		const calcY = (x - box.x - box.width / 2) / Constrain;

		return `perspective(100px) rotateX(${calcX}deg) rotateY(${calcY}deg)`;
	}

	function transformElement(el, xyEl) {
		el.style.transform = transforms(...xyEl);
	}

	function handleMouseMove(e) {
		const xy = [e.clientX, e.clientY];
		const position = xy.concat([ex1LayerRef.current]);

		window.requestAnimationFrame(() => {
			transformElement(ex1LayerRef.current, position);
		});
	}
	useEffect(() => {
		Aos.init({ duration: 1000 });
	}, []);

	return (
		<>
			<div className="w-[100vw] bg-white ">
				<div
					id="about"
					ref={aboutRef}
					className="max-w-7xl lg:grid max-w-7xl mx-auto gap-x-10 grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-y-0 xl:gap-x-20 flex  flex-col-reverse p-2 pt-24  justify-start items-center"
				>
					<div data-bg-opacity=".2" className="grid items-center">
						<div className="space-y-4 md:space-y-7">
							{/* <h1 className="font-semibold text-sky-500 text-lg md:text-xl flex items-center inline-flex"><span className='font-bold text-4xl'><BsDot/></span> About Us <span className='font-bold text-4xl'><BsDot/></span></h1> */}
							<h1
								className="font-semibold font-sans tracking-wide text-black text-2xl md:text-3xl lg:text-4xl xl:text-5xl capitalize"
								data-aos="fade-up"
							>
								trusted by worldwide clients since
								<span className="text-sky-500 solution flex justify-center items-center flex-col inline-flex relative">
									<span> 2020.</span>
									<span>
										<svg
											className="inline-block xl:h-8 md:w-20 w-16  xl:w-28 absolute top-6 left-0"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 500 150"
											preserveAspectRatio="none"
										>
											<path
												d="M7.7,145.6C109,125,299.9,116.2,401,121.3,442.1,123.5,487.6,133.1,487.3,147"
												fill="#0EA5E9"
											></path>
										</svg>
									</span>
								</span>
							</h1>
							<div data-aos="fade-up">
								<p className="font-light font-sans leading-relaxed tracking-normal text-black sm:text-base text-sm my-8">
									Elevating businesses with innovative IT solutions for enhanced
									efficiency, growth, and customer satisfaction.
								</p>
								<div className="xl:grid grid-cols-1 md:grid-cols-2  flex justify-start items-start flex-col gap-x-8 gap-y-16   ">
									{" "}
									{AboutData.map((item, index) => (
										<AboutCard key={index} data={item} />
									))}
								</div>

								<div className="flex justify-between items-center py-10">
									<button className="flex Borderrounded items-center space-x-3 border  border-2 border-sky-500 py-2 px-6 sm:px-10 hover:bg-white bg-sky-500 hover:text-sky-500 text-white   transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-105 duration-300">
										<a className="font-semibold  text-sm sm:text-lg   ">
											Get In Touch
										</a>
									</button>
								</div>
							</div>
						</div>
					</div>

					<div
						ref={mouseOverContainerRef}
						onMouseMove={handleMouseMove}
						data-aos="fade-up"
						className="w-full h-full flex justify-center items-center sm:py-10"
					>
						<img
							id="ex1-layer"
							ref={ex1LayerRef}
							className="min-w-sm sm:max-w-2xl md:max-w-lg 2xl:max-w-full"
							src={icon}
							alt="icon"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default AboutUs;
