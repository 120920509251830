import React, { useEffect, useState, useRef } from "react";

import { BsSearch } from "react-icons/bs";
import { CiDark } from "react-icons/ci";
import { BiUpArrowAlt } from "react-icons/bi";
import whitelogo from "../img/logo-01.svg";
import skybluelogo from "../img/logo-02.svg";
const Navbar = ({ handleScrollToSection }) => {
	const [toggle, setToggle] = useState(false);

	const [scrolling, setScrolling] = useState(false);

	useEffect(() => {
		// Add scroll event listener when the component mounts
		window.addEventListener("scroll", handleScroll);

		// Remove the scroll event listener when the component unmounts
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const handleScroll = () => {
		if (window.scrollY > 20) {
			setScrolling(true);
		} else {
			setScrolling(false);
		}
	};

	return (
		<>
			<div
				id="navbar"
				style={{
					background: scrolling ? "rgb(14, 168, 230)" : "transparent",
					position: "fixed",
					top: "0",
					width: "100vw ",
					zIndex: "100",
					margin: "0",
				}}
			>
				<div className="max-w-7xl mx-auto  m-0 ">
					<header style={{ backgroundcolor: "transparent" }}>
						<nav className="mx-auto flex items-center justify-between gap-x-6 p-2">
							<div className="flex">
								<a
									className="-m-1.5 p-1.5 text-black flex justify-center items-center "
									href="/"
								>
									<img
										src={scrolling ? whitelogo : skybluelogo}
										alt="logo "
										className="w-40 text-white"
									/>
								</a>
							</div>

							<div className="hidden gap-x-6 lg:flex xl:gap-x-12">
								<a
									target=""
									className={`${
										scrolling ? "text-white  " : "text-black hover:text-sky-600"
									} font-bold font-sans text-sm xl:text-base cursor-pointer`}
									onClick={() => handleScrollToSection("home")}
								>
									Home
								</a>

								<a
									target=""
									className={`${
										scrolling ? "text-white  " : "text-black hover:text-sky-600"
									} font-bold font-sans text-sm xl:text-base cursor-pointer`}
									onClick={() => handleScrollToSection("service")}
								>
									Service
								</a>
								<a
									target=""
									className={`${
										scrolling ? "text-white  " : "text-black hover:text-sky-600"
									} font-bold font-sans text-sm xl:text-base cursor-pointer`}
									onClick={() => handleScrollToSection("about")}
								>
									About
								</a>
								<a
									target=""
									className={`${
										scrolling ? "text-white  " : "text-black hover:text-sky-600"
									} font-bold font-sans text-sm xl:text-base cursor-pointer`}
									onClick={() => handleScrollToSection("portfolio")}
								>
									Portfolio
								</a>
								<a
									target=""
									className={`${
										scrolling ? "text-white  " : "text-black hover:text-sky-600"
									} font-bold font-sans text-sm xl:text-base cursor-pointer`}
									onClick={() => handleScrollToSection("contact")}
								>
									Contact
								</a>
							</div>
							<div className="flex justify-center items-center  flex-row">
								<div className="flex lg:hidden">
									<button
										className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-black"
										onClick={() => {
											setToggle(!toggle);
										}}
									>
										<span className="sr-only">Open main menu</span>
										<span
											className={`w-fit ${
												scrolling ? "text-white" : "text-black"
											}`}
										>
											<svg
												className="h-8 w-8"
												fill="none"
												viewBox="0 0 24 24"
												stroke-width="1.5"
												stroke="currentColor"
												aria-hidden="true"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
												></path>
											</svg>
										</span>
									</button>
								</div>
							</div>
						</nav>
						{toggle && (
							<div className="lg:hidden fixed inset-y-0 right-0 z-50 w-full bg-black/80 px-6 py-4 sm:min-w-{100vw} sm:ring-1 sm:ring-gray-900/10 ">
								<div className="fixed inset-y-0 right-0 z-50 w-full bg-black px-6 py-4 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
									<div className="gap-x-6 flex justify-between">
										<a className="-m-1.5 p-1.5 text-white" href="/">
											<span className="sr-only">Your Company</span>
											<img
												src={whitelogo}
												alt="logo "
												className="w-40 text-white"
											/>
										</a>
										<button
											className="-m-2.5 rounded-md p-2.5 text-white"
											onClick={() => {
												setToggle(!toggle);
											}}
										>
											<span className="sr-only">Close menu</span>
											<span className="w-fit">
												<svg
													className="h-8 w-8"
													fill="none"
													viewBox="0 0 24 24"
													stroke-width="1.5"
													stroke="currentColor"
													aria-hidden="true"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														d="M6 18L18 6M6 6l12 12"
													></path>
												</svg>
											</span>
										</button>
									</div>
									<div className="mt-6 flow-root">
										<div className="-my-6 divide-y divide-gray-500/10">
											<div className="space-y-2 py-6">
												<a
													target=""
													className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-sky-600"
													href="/#home"
													onClick={() => {
														setToggle(!toggle);
													}}
												>
													Home
												</a>

												<a
													target=""
													className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-sky-600"
													href="/#service"
													onClick={() => {
														setToggle(!toggle);
													}}
												>
													Service
												</a>
												<a
													target=""
													className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-sky-600"
													href="/#about"
													onClick={() => {
														setToggle(!toggle);
													}}
												>
													About
												</a>
												<a
													target=""
													className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-sky-600"
													href="/#portfolio"
													onClick={() => {
														setToggle(!toggle);
													}}
												>
													Portfolio
												</a>
												<a
													target=""
													className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-sky-600"
													href="/#contact"
													onClick={() => {
														setToggle(!toggle);
													}}
												>
													Contact
												</a>
											</div>
											<div className="flex flex-1 items-center gap-x-6 py-6">
												<button className="rounded-md shadow-sm bg-transparent font-bold border border-black py-3 px-4 text-[10px] text-black xl:py-5 xl:px-8 xl:text-sm transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-105 duration-300">
													<BsSearch />
												</button>
												<button className="rounded-md shadow-sm bg-transparent font-bold border border-black py-3 px-4 text-[10px] text-black xl:py-5 xl:px-8 xl:text-sm transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-105 duration-300">
													Connect Wallet
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</header>
				</div>
			</div>
			{/* style={{ display: scrolling ? 'flex' : 'none',position:'fixed',bottom : "50px", right : "50px" ,width :"80px" , height : "80px" ,  zIndex: "100" , margin : "0" }} */}
			{!toggle && (
				<div
					id="homeButton"
					className={` ${
						scrolling ? "flex" : "hidden"
					} fixed sm:bottom-[50px] bottom-[5px] right-[5px] sm:right-[50px] w-[80px] h-[80px] z-[200] z-40 m-0`}
				>
					<a onClick={() => handleScrollToSection("home")}>
						<button className="rounded-lg text-3xl text-white bg-sky-500 hover:bg-sky-400  w-14 h-14  flex justify-center items-center transition ease-in-out delay-100 hover:-translate-y-2  duration-300   shadow shadow-xl shadow-black/50  ">
							<BiUpArrowAlt />
						</button>
					</a>
				</div>
			)}
		</>
	);
};

export default Navbar;
