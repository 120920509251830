import React, { useState, useRef } from "react";
import { PortfolioData } from "./PortfolioData";
import PortfolioCard from "./PortfolioCard";
import { BsArrowRight } from "react-icons/bs";
import { BsDot } from "react-icons/bs";
import { useEffect } from "react";
import Aos from "aos";

const Portfolio = () => {
	const portfolioRef = useRef(null);
	const [data, setData] = useState(PortfolioData);
	const filterType = (category) => {
		setData(
			PortfolioData.filter((item) => {
				return item.category === category;
			})
		);
	};
	useEffect(() => {
		Aos.init({ duration: 1000 });
	}, []);

	const scrollToPortfolio = () => {
		if (portfolioRef.current) {
			portfolioRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<div id="portfolio" ref={portfolioRef} className="w-[100vw] bg-white pt-20">
			<div
				data
				className="max-w-7xl bg-cover  mx-auto bg-no-repeat bg-center  lg:block  ComponentSection_herosectionBg__fVpcc p-2 "
			>
				<div className=" mx-auto flex  justify-between items-start lg:items-center lg:flex-row flex-col gap-y-4">
					<div className="space-y-4 md:space-y-7 max-w-lg ">
						{/* <h1 className="font-semibold text-sky-500 text-lg md:text-xl flex items-center inline-flex"><span className='font-bold text-4xl'><BsDot/></span> PORTFOLIO<span className='font-bold text-4xl'><BsDot/></span></h1> */}

						<h1
							className="font-semibold text-black text-2xl md:text-3xl lg:text-4xl xl:text-5xl "
							data-aos="fade-up"
						>
							Our Portfolio
						</h1>
					</div>
					<div className="flex gap-x-4 xl:gap-x-8 lg:ml-0 ml-4">
						<button
							className="flex Borderrounded items-center space-x-3 border  border-2 border-sky-500 py-2 px-6 sm:px-10 hover:bg-white bg-sky-500 hover:text-sky-500 text-white   transition ease-in-out delay-100 hover:-translate-x-1 hover:scale-105 duration-300"
							data-aos="fade-up"
						>
							{/* <img alt="blockaudit" className="w-8 h-8" src={blockaudit} /> */}
							See More{" "}
							<span className="inline-flex mx-2 font-bold">
								<BsArrowRight />
							</span>
						</button>
					</div>
				</div>
				<div
					className="flex justfiy-between flex-wrap my-8 font-medium text-xl"
					data-aos="fade-up"
				>
					<button
						onClick={() => setData(PortfolioData)}
						className="mx-3 my-2 capitalize  hover:text-sky-600 text-black font-normal font-sans"
					>
						All
					</button>
					<button
						onClick={() => filterType("mobile-apps")}
						className="mx-3 my-2 capitalize  hover:text-sky-600 text-black font-normal font-sans"
					>
						DeFi Application
					</button>
					<button
						onClick={() => filterType("cloud")}
						className="mx-3 my-2 capitalize  hover:text-sky-600 text-black font-normal font-sans"
					>
						Website Development
					</button>
					<button
						onClick={() => filterType("data-analaysis")}
						className="mx-3 my-2 capitalize  hover:text-sky-600 text-black font-normal font-sans"
					>
						Mobile App
					</button>
					<button
						onClick={() => filterType("hosting")}
						className="mx-3 my-2 capitalize  hover:text-sky-600 text-black font-normal font-sans"
					>
						Smart Contract
					</button>
					<button
						onClick={() => filterType("cloud")}
						className="mx-3 my-2 capitalize  hover:text-sky-600 text-black font-normal font-sans"
					>
						Salesforce Application
					</button>
				</div>

				<div className="sm:grid max-w-7xl mx-auto  grid-cols-1 sm:grid-cols-2  lg:grid-cols-3   gap-5 flex flex-col  justify-center items-center  sm:p-0 p-3 mt-3">
					<div
						className="sm:grid max-w-7xl mx-auto    grid-cols-1 sm:grid-cols-2    gap-5 flex flex-col  justify-center items-center col-span-2"
						data-aos="fade-up"
					>
						{" "}
						{data.map((item, index) => (
							<PortfolioCard key={index} data={item} />
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Portfolio;

// const Food = () => {
//   const [foods, setFoods] = useState(data);
//   const filterType = (category) => {
//     setFoods(
//       data.filter((item) => {
//         return item.category === category;
//       })
//     );
//   };

//   const filterPrice = (price) => {
//     setFoods(
//       data.filter((item) => {
//         return item.price === price;
//       })
//     );
//   };

//   return (
//     <div className='max-w-[1640px] m-auto px-4 py-12'>
//       <h1 className='text-orange-600 font-bold text-4xl text-center'>
//         Top Rated Menu Items
//       </h1>

//       <div className='flex flex-col lg:flex-row justify-between'>
//         <div>
//           <p className='font-bold text-gray-700'>Filter Type</p>
//           <div className='flex justfiy-between flex-wrap'>
//             <button
//               onClick={() => setFoods(data)}
//               className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'
//             >
//               All
//             </button>
//             <button
//               onClick={() => filterType('burger')}
//               className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'
//             >
//               Burgers
//             </button>
//             <button
//               onClick={() => filterType('pizza')}
//               className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'
//             >
//               Pizza
//             </button>
//             <button
//               onClick={() => filterType('salad')}
//               className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'
//             >
//               Salads
//             </button>
//             <button
//               onClick={() => filterType('chicken')}
//               className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'
//             >
//               Chicken
//             </button>
//           </div>
//         </div>
//         <div>
//           <p className='font-bold text-gray-700'>Filter Price</p>
//           <div className='flex justify-between max-w-[390px] w-full'>
//             <button
//               onClick={() => filterPrice('$')}
//               className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'
//             >
//               $
//             </button>
//             <button
//               onClick={() => filterPrice('$$')}
//               className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'
//             >
//               $$
//             </button>
//             <button
//               onClick={() => filterPrice('$$$')}
//               className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'
//             >
//               $$$
//             </button>
//             <button
//               onClick={() => filterPrice('$$$$')}
//               className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'
//             >
//               $$$$
//             </button>
//           </div>
//         </div>
//       </div>

//       <div className='grid grid-cols-2 lg:grid-cols-4 gap-6 pt-4'>
//         {foods.map((item, index) => (
//           <div
//             key={index}
//             className='border shadow-lg rounded-lg hover:scale-105 duration-300'
//           >
//             <img
//               src={item.image}
//               alt={item.name}
//               className='w-full h-[200px] object-cover rounded-t-lg'
//             />
//             <div className='flex justify-between px-2 py-4'>
//               <p className='font-bold'>{item.name}</p>
//               <p>
//                 <span className='bg-orange-500 text-white p-1 rounded-full'>
//                   {item.price}
//                 </span>
//               </p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Food;
