import image1 from "../../img/1.jpg";
import image2 from "../../img/2.jpg";
import image3 from "../../img/3.jpg";
import image4 from "../../img/4.jpg";
import image5 from "../../img/5.jpg";
import image6 from "../../img/6.jpg";

export const PortfolioData = [
	{
		id: 1,
		category: "mobile-apps",
		image: image1,
	},
	{
		id: 2,
		category: "cloud",
		image: image2,
	},
	{
		id: 3,
		category: "data-analaysis",
		image: image3,
	},
	{
		id: 4,
		category: "hosting",
		image: image4,
	},
	{
		id: 5,
		category: "mobile-apps",
		image: image5,
	},
	{
		id: 6,
		category: "mobile-apps",
		image: image6,
	},
];

export const ProjectPortfoliaData = [
	{
		id: 1,
		category: "mobile-apps",
		image: image1,
	},
	{
		id: 2,
		category: "cloud",
		image: image2,
	},
	{
		id: 3,
		category: "data-analaysis",
		image: image3,
	},
	{
		id: 4,
		category: "hosting",
		image: image4,
	},
	{
		id: 5,
		category: "mobile-apps",
		image: image5,
	},
	{
		id: 6,
		category: "mobile-apps",
		image: image6,
	},
];
