import React from "react";
import Hero from "./Hero";
import Service from "./Service/Service";
import AboutUs from "./About/AboutUs";
import Working from "./Working/Working";
import Portfolio from "./Portfolio/Portfolio";
import Testimonials from "./Testimonals/TestimonalsMain";
// import Footer from "./Footer";
import Navbar from "./Navbar";
const Home = () => {
	const handleScrollToSection = (sectionId) => {
		const sectionElement = document.getElementById(sectionId);
		if (sectionElement) {
			sectionElement.scrollIntoView({ behavior: "smooth" });
		}
	};
	return (
		<>
			<Navbar handleScrollToSection={handleScrollToSection} />
			<Hero />
			<Service />
			<AboutUs />
			<Working />
			<Portfolio />
			<Testimonials />
			{/* <Footer /> */}
		</>
	);
};

export default Home;
