import React, { useRef } from "react";
import icon from "../img/logo192.png";

const Constrain = 200;

const Hero = () => {
	const homeRef = useRef(null);
	const mouseOverContainerRef = useRef(null);
	const ex1LayerRef = useRef(null);

	function transforms(x, y, el) {
		const box = el.getBoundingClientRect();
		const calcX = -(y - box.y - box.height / 2) / Constrain;
		const calcY = (x - box.x - box.width / 2) / Constrain;

		return `perspective(100px) rotateX(${calcX}deg) rotateY(${calcY}deg)`;
	}

	function transformElement(el, xyEl) {
		el.style.transform = transforms(...xyEl);
	}

	function handleMouseMove(e) {
		const xy = [e.clientX, e.clientY];
		const position = xy.concat([ex1LayerRef.current]);

		window.requestAnimationFrame(() => {
			transformElement(ex1LayerRef.current, position);
		});
	}
	return (
		<>
			<div
				id="home"
				ref={homeRef}
				className="bg-cover  bg-white  mx-auto bg-no-repeat bg-center mt-[-105px] md:mt-[-131px]  lg:pt-72 pt-60 pb-12 lg:pb-40 lg:block xl:px-0  p-2 min-h-[90vh]"
			>
				<div className="max-w-7xl lg:grid max-w-7xl mx-auto  grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-y-0  flex flex-col justify-center items-center">
					<div
						data-bg-opacity=".2"
						className="flex flex-col items-center justify-center "
					>
						<div className="space-y-4 md:space-y-7">
							{/* <div className="flex justify-start items-center my-3">
                <button className="rounded-lg text-lg text-black   hover:text-sky-500 w-8 h-8 flex justify-center items-center transition ease-in-out delay-100 hover:-translate-y-2  duration-300  mr-2 ">
                  <a className="" href="#">
                    <TfiFacebook />
                  </a>
                </button>
                <button className="rounded-lg text-lg text-black   hover:text-sky-500 w-8 h-8 flex justify-center items-center transition ease-in-out delay-100 hover:-translate-y-2  duration-300  mr-2 ">
                  <a className="" href="#">
                    <FaYoutube />
                  </a>
                </button>
                <button className="rounded-lg text-lg text-black   hover:text-sky-500 w-8 h-8 flex justify-center items-center transition ease-in-out delay-100 hover:-translate-y-2  duration-300  mr-2 ">
                  <a className="" href="#">
                    <BsInstagram />
                  </a>
                </button>
                <button className="rounded-lg text-lg text-black   hover:text-sky-500 w-8 h-8 flex justify-center items-center transition ease-in-out delay-100 hover:-translate-y-2  duration-300  mr-2 ">
                  <a className="" href="#">
                    <FaTwitter />
                  </a>
                </button>
              </div> */}
							{/* <h1 className="font-bold text-sky-500 text-lg md:text-xl flex items-center inline-flex"><span className='font-bold text-4xl'><BsDot/></span>IT SOLUTIONS<span className='font-bold text-4xl'><BsDot/></span></h1> */}

							<h1 className=" text-black text-4xl xl:text-6xl font-extrabold font-sans ">
								Providing The Best Services & IT{" "}
								<span className="text-sky-500 solution flex justify-center items-center flex-col inline-flex relative">
									<span>Solution</span>
									<span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="200"
											height="50"
											viewBox="0 0 698 125"
											fill="none"
										>
											<path
												d="M5 110C164 18.9999 664.5 -65.5 687.5 123.5"
												stroke="#00A1E2"
												stroke-width="25"
											/>
										</svg>
									</span>
								</span>
							</h1>
							<p className="text-black/70 font-light  text-base md:text-lg font-normal font-sans  ">
								We offers diverse services including custom software
								development, web and mobile app creation, UX/UI design, software
								maintenance, testing, cloud solutions, and consultancy for
								tailored digital solutions.
							</p>
							<div className="flex gap-x-4 xl:gap-x-8">
								<button className="flex Borderrounded items-center space-x-3 border  border-2 border-sky-500 py-2 px-4 lg:px-6 xl:py-3 xl:px-10 hover:bg-sky-500 text-sky-500 hover:text-white  transition ease-in-out delay-100  duration-300">
									<a
										className="font-semibold  text-xs xl:text-lg"
										href="https://api.whatsapp.com/send?phone=918955562054"
										target="_blank"
									>
										Contact us
									</a>
								</button>
							</div>
						</div>
					</div>

					<div
						id="ex1"
						ref={mouseOverContainerRef}
						onMouseMove={handleMouseMove}
						onMouseOut={null}
						className="w-full h-full flex justify-center items-center"
					>
						<img
							id="ex1-layer"
							ref={ex1LayerRef}
							className="min-w-sm sm:max-w-2xl md:max-w-lg 2xl:max-w-full"
							src={icon}
							alt="icon"
						/>
					</div>
				</div>
			</div>
		</>
	);
};
export default Hero;
