import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import img1 from "../../img/1_002.png";
import img2 from "../../img/2.png";
import img3 from "../../img/3_002.png";

export default class Testimonials extends Component {
  render() {
    return (
      <>
        <div className="mb-10 bg-black-50 w-100vw">
          <Carousel
            showArrows={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            interval={6100}
            className="bg-transparent"
          >
            <div className="w-full flex justify-center items-center flex-col">
              <h1 className="text-sky-500 text-xl font-bold my-3">
                {" "}
                TESTMONIALS
              </h1>
              <h1 className="text-white  text-3xl xl:text-4xl 2xl:text-5xl capitalize font-bold my-3">
                {" "}
                customers testmonials
              </h1>
              <div className="w-16  h-16  p-1 my-5 rounded-full bg-sky-500 flex justify-center items-center ">
                <img className="rounded-full  h-full w-full" src={img1} />
              </div>
              <div className="myCarousel">
                <p className="font-normal sm:leading-10 leading-7 text-white text-base sm:text-xl 2xl:text-2xl font-sans max-h-[67px]  italic">
                  ipsum dolor sit amet consectetur adipisicing elit. Quod, id
                  sequi aut qui est ab, corporis quis maiores reiciendis
                  explicabo odio tenetur nulla sint vel.{" "}
                </p>
                <br />

                <h3 className="font-semibold text-xl text-white xl:mt-10 lg:mt-25  sm:mt-15 mt-7 sm:leading-10 leading-4">
                  Shirley Fultz
                </h3>
                <h4 className="font-medium text-base text-white  my-1">
                  Designer
                </h4>
              </div>
            </div>

            <div className="w-full flex justify-center items-center flex-col">
              <h1 className="text-sky-500 text-xl font-bold my-3">
                {" "}
                TESTMONIALS
              </h1>
              <h1 className="text-white  text-3xl xl:text-4xl 2xl:text-5xl capitalize font-bold my-3">
                {" "}
                customers testmonials
              </h1>

              <div className="w-16  h-16  p-1 my-5 rounded-full bg-sky-500 flex justify-center items-center ">
                <img className="rounded-full h-full w-full " src={img2} />
              </div>
              <div className="myCarousel">
                <p className="font-normal sm:leading-10 leading-7 text-white text-base sm:text-xl  2xl:text-2xl font-sans max-h-[67px]  italic">
                  ipsum dolor sit amet consectetur adipisicing elit. Quod, id
                  sequi aut qui est ab, corporis quis maiores reiciendis
                  explicabo odio tenetur nulla sint vel.
                </p>
                <br />

                <h3 className="font-semibold text-xl text-white  xl:mt-10 lg:mt-25  sm:mt-15 mt-7 sm:leading-10 leading-4">
                  Daniel Keystone
                </h3>
                <h4 className="font-medium text-base text-white  my-1">
                  Designer
                </h4>
              </div>
            </div>

            <div className="w-full flex justify-center items-center flex-col">
              <h1 className="text-sky-500 text-xl font-bold my-3">
                {" "}
                TESTMONIALS
              </h1>
              <h1 className="text-white  text-3xl xl:text-4xl 2xl:text-5xl capitalize font-bold my-3">
                {" "}
                customers testmonials
              </h1>

              <div className="w-16  h-16  p-1 my-5 rounded-full bg-sky-500 flex justify-center items-center ">
                <img className="rounded-full h-full w-full " src={img3} />
              </div>
              <div className="myCarousel">
                <p className="font-normal sm:leading-10 leading-7 text-white text-base sm:text-xl  2xl:text-2xl font-sans max-h-[67px]  italic">
                  ipsum dolor sit amet consectetur adipisicing elit. Quod, id
                  sequi aut qui est ab, corporis quis maiores reiciendis
                  explicabo odio tenetur nulla sint vel.
                </p>
                <br />
                <h3 className="font-semibold text-xl text-white  xl:mt-10 lg:mt-25  sm:mt-15 mt-7 sm:leading-10 leading-4">
                  Theo Sorel
                </h3>
                <h4 className="font-medium text-base text-white  my-1">
                  Designer
                </h4>
              </div>
            </div>
          </Carousel>
        </div>
      </>
    );
  }
}
