import React from "react";
import Testimonials from "./Testimonals";
import { useEffect } from "react";
import Aos from "aos";

const TestimonalsMain = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <>
      <div className="w-full h-16 bg-white"></div>

      {/* <div className="bg-transparent"> */}
      <div className="bg-black/50">
        <div className="w-full   py-2 bg-black/60">
          <div className="w-full" data-aos="fade-up">
            <Testimonials />
          </div>
        </div>
      </div>
      {/* <div className='w-full h-16 bg-white'>
</div> */}
    </>
  );
};

export default TestimonalsMain;
