import React, { useRef } from "react";
import icon from "../../img/1.png";
import { BsDot } from "react-icons/bs";
import WorkingCard from "./WorkingCard";
import { WorkingData } from "./WorkingData";
import { useEffect } from "react";
import Aos from "aos";

const Constrain = 200;

const Working = () => {
  const mouseOverContainerRef = useRef(null);
  const ex1LayerRef = useRef(null);

  function transforms(x, y, el) {
    const box = el.getBoundingClientRect();
    const calcX = -(y - box.y - box.height / 2) / Constrain;
    const calcY = (x - box.x - box.width / 2) / Constrain;

    return `perspective(100px) rotateX(${calcX}deg) rotateY(${calcY}deg)`;
  }

  function transformElement(el, xyEl) {
    el.style.transform = transforms(...xyEl);
  }

  function handleMouseMove(e) {
    const xy = [e.clientX, e.clientY];
    const position = xy.concat([ex1LayerRef.current]);

    window.requestAnimationFrame(() => {
      transformElement(ex1LayerRef.current, position);
    });
  }
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <>
      <div className="w-[100vw] bg-white">
        <div className="max-w-7xl lg:grid max-w-7xl mx-auto gap-x-10 grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-y-0 xl:gap-x-20 flex  flex-col  pt-20 p-2 justify-center items-center">
          <div
            id="ex1"
            ref={mouseOverContainerRef}
            onMouseMove={handleMouseMove}
            data-aos="fade-up"
            className="w-full h-full flex justify-center items-center"
          >
            <img
              id="ex1-layer"
              ref={ex1LayerRef}
              className="min-w-sm sm:max-w-2xl md:max-w-lg 2xl:max-w-full"
              src={icon}
              alt="icon"
            />
          </div>
          <div data-bg-opacity=".2" className="grid items-center ">
            <div className="space-y-4 md:space-y-7">
              {/* <h1 className="font-semibold text-sky-500 text-lg md:text-xl flex items-center inline-flex uppercase"><span className='font-bold text-4xl'><BsDot/></span> Why Choose Us <span className='font-bold text-4xl'><BsDot/></span></h1> */}
              <h1
                className="font-semibold font-sans tracking-wide text-black text-2xl md:text-3xl lg:text-4xl xl:text-5xl"
                data-aos="fade-up"
              >
                Why our customers choose{" "}
                <span className="text-sky-500 solution flex justify-center items-center flex-col inline-flex relative">
                  <span>Working</span>
                  <span>
                    <svg
                      className="inline-block h-8  xl:w-48 lg:w-40  sm:w-28 w-24 absolute xl:top-8 lg:top-5 sm:top-3 top-2 left-0"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 500 150"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M7.7,145.6C109,125,299.9,116.2,401,121.3,442.1,123.5,487.6,133.1,487.3,147"
                        fill="#0EA5E9"
                      ></path>
                    </svg>
                  </span>
                </span>{" "}
                with us
              </h1>
              <div data-aos="fade-up">
                <p className="font-light font-sans leading-relaxed tracking-normal text-black sm:text-base text-sm my-20 ">
                Customers choose us for top-notch IT solutions, seamless project delivery, and unparalleled expertise, ensuring success in every technological endeavor.
                </p>
                <div className=" flex justify-start items-start flex-col gap-x-8 gap-y-16   ">
                  <div className="flex justify-start items-start flex-row ">
                    <div className="text-sky-500 text-2xl sm:text-3xl xl:text-4xl 2xl:text-5xl  font-bold ">
                      01.
                    </div>
                    <div className="flex justify-start items-start  flex-col mx-4 ">
                      <h1 className="font-semibold  w-full max-w-lg  text-base sm:text-xl text-black capitalize mb-2">
                        {" "}
                        Latest technologies{" "}
                      </h1>
                      <p className="sm:text-base text-xs font-normal">
                      Embrace cutting-edge technologies for innovative software solutions, ensuring your business stays ahead in the ever-evolving digital landscape.
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-start items-start flex-row ">
                    <div className="text-sky-500 text-2xl sm:text-3xl xl:text-4xl 2xl:text-5xl  font-bold ">
                      02.
                    </div>
                    <div className="flex justify-start items-start  flex-col mx-4 ">
                      <h1 className="font-semibold  w-full max-w-lg  text-base sm:text-xl text-black capitalize mb-2">
                        Unique solutions{" "}
                      </h1>
                      <p className="sm:text-base text-xs font-normal">
                      Tailored software solutions that stand out, addressing your specific business needs and delivering unparalleled value and efficiency.
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-start items-start flex-row ">
                    <div className="text-sky-500 text-2xl sm:text-3xl xl:text-4xl 2xl:text-5xl  font-bold ">
                      03.
                    </div>
                    <div className="flex justify-start items-start  flex-col mx-4 ">
                      <h1 className="font-semibold  w-full max-w-lg  text-base sm:text-xl text-black capitalize mb-2">
                        {" "}
                        Powerful strategies{" "}
                      </h1>
                      <p className="sm:text-base text-xs font-normal">
                      Strategize effectively with our powerful IT solutions, driving business growth and success through comprehensive and result-driven strategies.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-center py-10">
                  <button className="flex Borderrounded items-center space-x-3 border  border-2 border-sky-500 py-2 px-6 sm:px-10 hover:bg-white bg-sky-500 hover:text-sky-500 text-white   transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-105 duration-300">
                    <a className="font-semibold  text-sm sm:text-lg   ">
                      Get In Touch
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" bgcover  bg-no-repeat bg-center Bgimage">
          <div className="bg-black/60  w-full h-full">
            <div className="max-w-7xl sm:grid max-w-7xl mx-auto grid-cols-1  sm:grid-cols-2 lg:grid-cols-4 gap-4 flex  flex-col   p-2 justify-start items-center py-20">
              {" "}
              {WorkingData.map((item, index) => (
                <WorkingCard key={index} data={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Working;
