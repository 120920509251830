import React, { useRef } from "react";
import { ServiceData } from "./ServiceData";
import ServiceCard from "./ServiceCard";
import { BsArrowRight } from "react-icons/bs";
import { BsDot } from "react-icons/bs";
import { useEffect } from "react";
import Aos from "aos";

function Service() {
	const serviceRef = useRef(null);
	useEffect(() => {
		Aos.init({ duration: 1000 });
	}, []);
	return (
		<div id="service" ref={serviceRef} className="w-[100vw] bg-white  pt-20 ">
			<div
				data
				className="max-w-7xl bg-cover  mx-auto bg-no-repeat bg-center  lg:block  ComponentSection_herosectionBg__fVpcc p-2"
			>
				<div className=" mx-auto flex  justify-between items-start lg:items-center lg:flex-row flex-col gap-y-4">
					<div className="space-y-4 md:space-y-7 max-w-lg ">
						{/* <h1 className="font-semibold text-sky-500 text-lg md:text-xl flex items-center inline-flex"><span className='font-bold text-4xl'><BsDot/></span> Service <span className='font-bold text-4xl'><BsDot/></span></h1>
						 */}
						<h1
							className="font-semibold font-sans tracking-wide text-black text-3xl lg:text-4xl xl:text-5xl"
							data-aos="fade-up"
						>
							Service We Offer
						</h1>
						<p
							className="font-normal font-sans  leading-8 text-black sm:text-base text-sm  "
							data-aos="fade-up"
						>
							Explore our comprehensive solutions: blockchain expertise,
							innovative web design, and top-notch mobile app development for
							your digital success.
						</p>
					</div>

					<div className="flex gap-x-4 xl:gap-x-8 lg:ml-0 ml-4">
						<button
							className="flex Borderrounded items-center space-x-3 border  border-2 border-sky-500 py-2 px-6 sm:px-10 hover:bg-white bg-sky-500 hover:text-sky-500 text-white   transition ease-in-out delay-100 hover:-translate-x-2 hover:scale-105 duration-300"
							data-aos="fade-up"
						>
							{/* <img alt="blockaudit" className="w-8 h-8" src={blockaudit} /> */}
							<a className="font-semibold text-sm sm:text-lg  ">
								See All Service{" "}
								<span className="inline-flex mx-2 font-bold">
									<BsArrowRight />
								</span>
							</a>
						</button>
					</div>
				</div>
				<div className="sm:grid max-w-7xl mx-auto gap-x-10   grid-cols-1 sm:grid-cols-2  lg:grid-cols-3   md:gap-x-5 lg:gap-x-16 xl:gap-x-5 gap-5 flex flex-col  justify-center items-center  sm:p-0 p-3 mt-16">
					{" "}
					{ServiceData.map((item, index) => (
						<ServiceCard key={index} data={item} />
					))}
				</div>
			</div>
		</div>
	);
}

export default Service;
