import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import image1 from "../../img/1.jpg";
import image2 from "../../img/2.jpg";
import image3 from "../../img/3.jpg";
import image4 from "../../img/4.jpg";
import image5 from "../../img/5.jpg";
import image6 from "../../img/6.jpg";
import icon from "../../img/seal.png";
import PortfolioTestimonial from "../../Components2/PortfolioTestimonial";
import { RiHome2Line } from "react-icons/ri";
import { MdContactMail, MdOutlineDateRange } from "react-icons/md";
import { TfiFacebook } from "react-icons/tfi";
import { BsInstagram, BsListCheck } from "react-icons/bs";
import { FaLinkedinIn, FaWhatsapp, FaShareAlt } from "react-icons/fa";

const PortfolioPage = () => {
	const Data = [
		{
			id: 1,
			image: image1,
			projectGoals: {
				paragraph1:
					"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos.",
				paragraph2:
					"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos. deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos.",
			},
			projectResult:
				"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam deleniti repudiandae aliqua",
			projectOverview: {
				paragraph1:
					"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam .",
				paragraph2:
					" Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos. deleniti repudiandae aliquam exercitationem veniam ipsam",
			},

			icon: icon,
			category: "mobile-apps",
		},
		{
			id: 2,
			image: image2,
			projectGoals: {
				paragraph1:
					"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos.",
				paragraph2:
					"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos. deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos.",
			},
			projectResult:
				"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam deleniti repudiandae aliqua",
			projectOverview: {
				paragraph1:
					"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam .",
				paragraph2:
					" Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos. deleniti repudiandae aliquam exercitationem veniam ipsam",
			},
			icon: icon,
			category: "cloud",
		},
		{
			id: 3,
			image: image3,
			projectGoals: {
				paragraph1:
					"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos.",
				paragraph2:
					"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos. deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos.",
			},
			projectResult:
				"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam deleniti repudiandae aliqua",
			projectOverview: {
				paragraph1:
					"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam .",
				paragraph2:
					" Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos. deleniti repudiandae aliquam exercitationem veniam ipsam",
			},
			icon: icon,
			category: "data-analaysis",
		},
		{
			id: 4,
			image: image4,
			projectGoals: {
				paragraph1:
					"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos.",
				paragraph2:
					"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos. deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos.",
			},
			projectResult:
				"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam deleniti repudiandae aliqua",
			projectOverview: {
				paragraph1:
					"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam .",
				paragraph2:
					" Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos. deleniti repudiandae aliquam exercitationem veniam ipsam",
			},
			icon: icon,
			category: "hosting",
		},
		{
			id: 5,
			image: image5,
			projectGoals: {
				paragraph1:
					"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos.",
				paragraph2:
					"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos. deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos.",
			},
			projectResult:
				"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam deleniti repudiandae aliqua",
			projectOverview: {
				paragraph1:
					"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam .",
				paragraph2:
					" Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos. deleniti repudiandae aliquam exercitationem veniam ipsam",
			},
			icon: icon,
			category: "mobile-apps",
		},
		{
			id: 6,
			image: image6,
			projectGoals: {
				paragraph1:
					"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos.",
				paragraph2:
					"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos. deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos.",
			},
			projectResult:
				"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam deleniti repudiandae aliqua",
			projectOverview: {
				paragraph1:
					"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam .",
				paragraph2:
					" Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam unde, alias deserunt nemo perspiciatis cum delectus deleniti repudiandae aliquam exercitationem veniam ipsam incidunt sequi ipsum tenetur. Consequuntur dolorum suscipit eos. deleniti repudiandae aliquam exercitationem veniam ipsam",
			},
			icon: icon,
			category: "mobile-apps",
		},
	];
	const { id } = useParams();

	const [data, setData] = useState([]);

	useEffect(() => {
		const filterType = () => {
			setData(
				Data.filter((item, index) => {
					return index + 1 === parseInt(id);
				})
			);
		};
		filterType();
	}, [id]);

	return (
		<>
			<div
				id="home"
				className="bg-cover portfoliodiv  bg-white  mx-auto bg-no-repeat bg-center mt-[-105px] md:mt-[-131px]  min-h-[60vh] xl:h-[70vh] w-[100vw]  overflow-hidden relative "
			>
				<div className="flex justify-center items-center absolute top-0 left-0 w-full h-full  bg-[#02081d] opacity-70 z-20"></div>
				<div className="flex justify-center items-center flex-col absolute top-16 left-0 w-full h-full z-50 ">
					<h1 className=" text-3xl sm:text-4xl xl:text-6xl font-bold text-[#fff] font-sans">
						Portfolio Single
					</h1>
					<div className="flex justify-center items-center text-white m-4 outline-nono ">
						<NavLink to="/">
							<div className="flex justify-center items-center text-white hover:text-sky-600 cursor-pointer">
								<RiHome2Line className="text-xs sm:text-base" />
								<h3 className="sm:text-lg text-sm font-sans  mx-2 outline-none">
									Home
								</h3>
							</div>
						</NavLink>
						<h3 className="sm:text-lg text-sm font-sans  mx-2 text-gray-400">
							/
						</h3>

						<h3 className="sm:text-lg text-sm font-sans  mx-2 text-gray-400">
							Portfolio Single
						</h3>
					</div>
				</div>
			</div>
			{data.map((item, index) => (
				<div className="flex justify-center items-center m-0 p-0  bg-white ">
					<div className="max-w-7xl mx-auto flex justify-center items-center  flex-col py-10 sm:py-20 xl:py-28 xl:p-0 lg:px-5 sm:px-20 px-5">
						<div className="w-full max-h-[450px] flex justify-center items-center bg-cover  bg-red-900  overflow-hidden box-border  relative z-40 rounded-xl ">
							<img
								src={item.image}
								className="w-full  h-full z-50 object-cover rounded-lg"
							/>
						</div>
						<div className="w-full box-border lg:grid grid-cols-12 flex  flex-col justify-start items-start my-10 gap-5">
							<div className="col-span-9 flex justify-center items-center flex-col  min-h-max">
								<div className="w-full flex flex-col justify-start items-center">
									<h1 className="text-3xl font-semibold capitalize text-black font-sans w-full text-start my-4">
										Project Goals
									</h1>
									<p className="text-lg text-black/80 my-3 font-sans ">
										{item.projectGoals.paragraph1}
										<br />
										<br />

										{item.projectGoals.paragraph2}
									</p>

									<div className="w-full max-h-[450px] flex justify-center items-center bg-cover   overflow-hidden box-border  relative z-40 my-10">
										<img
											src={item.image}
											className="w-full  h-full z-50 object-cover rounded-lg"
										/>
									</div>
									<h1 className="text-3xl font-semibold capitalize text-black font-sans w-full text-start my-4">
										project overview
									</h1>

									<p className="text-lg text-black/80 my-3 font-sans ">
										{item.projectOverview.paragraph1}
										<br /> <br />
										{item.projectOverview.paragraph2}
									</p>
									<div className="grid md:grid-cols-2 grid-cols-1 justify-center items-center w-full my-8">
										<div className="col-span-1 items-center flex justify-start w-full flex-col">
											<h1 className="text-3xl font-semibold capitalize text-black font-sans w-full text-start my-4">
												project steps
											</h1>
											<ul className="w-full items-start justify-start flex flex-col">
												<li className="capitalize text-base font-sans  text-black/80 my-3 w-full text-start">
													<span className="text-sky-600  mr-2">&rarr;</span>{" "}
													ipsum dolor sit amet consectetur adipisicing
												</li>
												<li className="capitalize text-base font-sans  text-black/80 my-3 w-full text-start">
													<span className="text-sky-600  mr-2">&rarr;</span>{" "}
													quas, ea fuga vel quis officiis laborum quaerat{" "}
												</li>
												<li className="capitalize text-base font-sans  text-black/80 my-3 w-full text-start">
													<span className="text-sky-600  mr-2">&rarr;</span>{" "}
													alias fugiat aliquam soluta beatae numquam
												</li>
												<li className="capitalize text-base font-sans  text-black/80 my-3 w-full text-start">
													<span className="text-sky-600  mr-2">&rarr;</span>{" "}
													nobis maiores inventore harum pariatur possimus
												</li>
											</ul>
										</div>
										<div className="col-span-1 items-center flex justify-center w-full md:my-0 mt-10">
											<img
												className="lg:max-w-[300px] max-w-[200px] "
												src={item.icon}
											/>
										</div>
									</div>
									<div className="flex justify-center items-center w-full my-8 flex-col">
										<div className=" items-center flex justify-start w-full flex-col">
											<h1 className="text-3xl font-semibold capitalize text-black font-sans w-full text-start my-4">
												project results
											</h1>
											<p className="text-lg text-black/80 my-3 font-sans ">
												{item.projectResult}
											</p>
										</div>

										<div className="w-full h-16 bg-white"></div>

										{/* <div className="bg-transparent"> */}
										<div className="">
											<div className="w-full   py-2 ">
												<div className="w-full" data-aos="fade-up">
													<PortfolioTestimonial />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-span-3 py-10 w-full relative  h-full">
								<div className="flex justify-center items-start flex-col  min-h-max  w-full sticky top-24">
									<div className="grid lg:grid-cols-1 sm:grid-cols-2 grid-cols-1 lg:gap-y-10 md:gap-10 gap-5 w-full">
										<div className="w-full border border-sky-500 border-t-4 border-t-sky-500 flex justify-between items-center flex-row  p-3 py-5">
											<div className="flex justify-start items-center flex-col w-3/4">
												<h1 className="uppercase text-start w-full text-sky-600 leading-10 text-xl font-light">
													client
												</h1>
												<h1 className="capitalize text-black leading-10 text-start w-full text-xl">
													Amin Themes
												</h1>
											</div>
											<div className="flex justify-center items-center w-1/4">
												<MdContactMail className="text-5xl text-sky-300" />
											</div>
										</div>
										<div className="w-full border border-sky-500 border-t-4 border-t-sky-500 flex justify-between items-center flex-row  p-3">
											<div className="flex justify-start items-center flex-col w-3/4">
												<h1 className="uppercase text-start w-full text-sky-600 leading-10 text-xl font-light">
													category
												</h1>
												<h1 className="capitalize text-black leading-10 text-start w-full text-xl">
													{item.category}
												</h1>
											</div>
											<div className="flex justify-center items-center w-1/4">
												<BsListCheck className="text-5xl text-sky-300" />
											</div>
										</div>
										<div className="w-full border border-sky-500 border-t-4 border-t-sky-500 flex justify-between items-center flex-row  p-3">
											<div className="flex justify-start items-center flex-col w-3/4">
												<h1 className="uppercase text-start w-full text-sky-600 leading-10 text-xl font-light">
													Date
												</h1>
												<h1 className="capitalize text-black leading-10 text-start w-full text-xl">
													30/10/2022
												</h1>
											</div>
											<div className="flex justify-center items-center w-1/4">
												<MdOutlineDateRange className="text-5xl text-sky-300" />
											</div>
										</div>
										<div className="w-full border border-sky-500 border-t-4 border-t-sky-500 flex justify-between items-center flex-row  p-3">
											<div className="flex justify-start items-center flex-col w-3/4">
												<h1 className="uppercase text-start w-full text-sky-600 leading-10 text-xl font-light">
													share
												</h1>
												<div className="flex justify-start items-center text-start w-full my-4">
													<button className="rounded-lg shadowbutton text-xl text-white bg-sky-500 hover:bg-white hover:text-sky-500 w-10 h-10 flex justify-center items-center transition ease-in-out delay-100 hover:-translate-y-2  duration-300  mr-2 ">
														<a className="" href="#">
															<TfiFacebook />
														</a>
													</button>
													<button className="rounded-lg shadowbutton text-xl text-white bg-sky-500 hover:bg-white hover:text-sky-500 w-10 h-10 flex justify-center items-center transition ease-in-out delay-100 hover:-translate-y-2  duration-300  mr-2 ">
														<a
															href="https://api.whatsapp.com/send?phone=918955562054"
															target="_blank"
														>
															<FaWhatsapp />
														</a>
													</button>
													<button className="rounded-lg shadowbutton text-xl text-white bg-sky-500 hover:bg-white hover:text-sky-500 w-10 h-10 flex justify-center items-center transition ease-in-out delay-100 hover:-translate-y-2  duration-300  mr-2 ">
														<a
															href="https://www.instagram.com/rahul.saini.022/"
															target="_blank"
														>
															<BsInstagram />
														</a>
													</button>
													<button className="rounded-lg shadowbutton text-xl text-white bg-sky-500 hover:bg-white hover:text-sky-500 w-10 h-10 flex justify-center items-center transition ease-in-out delay-100 hover:-translate-y-2  duration-300  mr-2 ">
														<a
															href="https://www.linkedin.com/in/rahul-saini-b83610187/"
															target="_blank"
														>
															<FaLinkedinIn />
														</a>
													</button>
												</div>
											</div>
											<div className="flex justify-center items-center w-1/4">
												<FaShareAlt className="text-5xl text-sky-300" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			))}
		</>
	);
};

export default PortfolioPage;
