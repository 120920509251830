import React from "react";
import { useState } from "react";
// import { useEffect } from "react";
// import Aos from 'aos';

const AboutCard = (props) => {
  const [toggle, setToggle] = useState();
  const changeToggle = (index) => {
    setToggle(index);
  };
  //   useEffect(() => {
  //     Aos.init({ duration: 1000 })
  // }, [])

  return (
    <>
      <div
        onMouseOver={() => changeToggle(1)}
        onMouseOut={() => changeToggle(0)}
        // data-aos="fade-up"
        className="flex justify-start items-start flex-row "
      >
        <div
          className={`p-4  ${
            toggle === 1
              ? "bg-sky-500 text-white transition ease-in-out delay-100 -translate-y-1 duration-300 "
              : "bg-sky-100 text-sky-500 transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-105 duration-300 "
          }  text-3xl   rounded-lg `}
        >
          {props.data.icon}
        </div>
        <div className="flex justify-start items-start  flex-col mx-4 ">
          <h1 className="font-bold  w-full max-w-lg  text-xl text-black capitalize mb-2">
            {props.data.name}{" "}
          </h1>
          <p className="sm:text-base text-xs font-normal">
            {props.data.details}
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutCard;
